<template>
  <div>
    <div class="air__utils__heading">
      <h5>Check de Descarga (Servicio: {{spId}}/ {{spCarrierId}})</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <my-spinner :load="mainSpinner"/>
    <template v-show="!mainSpinner">
      <!-- CHECKLIST PRINCIPAL -->
      <template v-if="showDischargeCheck">
        <b-alert show variant="warning" class="text-center text-capitalize">
          Este servico ya cuenta con un checkList registrado, consulte su reporte
        </b-alert>
        <div class="row justify-content-center my-4">
          <responsive-button text="Impresión de Reporte" icon="printer-fill"
                             pill variant="secondary" size="sm" responsive="md"
                             :to="{name:'check_list_report', params: {checkListFormId: checkListFormId,checkListFormAuxId:gondolaCheckListFormId}}"/>
        </div>
      </template>
      <template v-else>
        <check-list v-if="!LOCK_SERVICE"
                    :checkLists="getCheckStructure(receptionState.dischargeCheck)"
                    @updateCheckListForm="dischargeCheckListForm = $event"
                    @totalFields="totalFields = $event">
          <b-container fluid slot="form">
            <a-form-model layout="horizontal" :model="dischargeForm" :rules="dischargeRules" ref="surveillance-Form">
              <!-- SWITCH PARA  GONDOLAS -->
              <a-row align="middle" justify="center">
                <a-col :span="2" :offset="11" >
                  <a-form-model-item label="¿Descarga segura?">
                    <a-switch @change="onGondola"
                              checked-children="SI" un-checked-children="NO" :defaultChecked="false" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <!-- SWITCH PARA  GONDOLAS -->
              <!-- CHECKLIST GONDOLAS -->
              <check-list v-if="showGondolaCheck"
                          :checkLists="getCheckStructure(receptionState.gondolaCheck)"
                          @updateCheckListForm="gondolaCheckListForm = $event"
                          @totalFields="gondolaTotalFields = $event"/>
              <!-- CHECKLIST GONDOLAS -->
              <a-row align="middle" justify="center">
                <a-col :sm="{ span: 24, }" :md="{ span:12}" >
                  <a-form-model-item label="Nombre" class="mx-1" ref="inspector_user_id" prop="inspector_user_id">
                    <a-select v-model="dischargeForm.inspector_user_id"
                              placeholder="Seleccionar"
                              :disabled="infoView"
                              show-search
                              :filter-option="filterOption"
                    >
                      <a-select-option  v-for="user in inspectorsCatalog" :key="user.jsonApi.id" :value="user.jsonApi.id" >
                        {{user.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :sm="{ span: 24, }" :md="{ span:6}" ref="date" prop="date">
                  <a-form-model-item label="Fecha de Inspección" class="mx-1">
                    <a-date-picker format="DD/MM/YYYY" v-model="dischargeForm.date" />
                  </a-form-model-item>
                </a-col>
                <a-col :sm="{ span: 24, }" :md="{ span:6}">
                  <a-form-model-item label="Hora Final de Inspección" class="mx-1"  ref="final_hour" prop="final_hour">
                    <a-time-picker v-model="dischargeForm.final_hour" format="HH:mm" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <div class="row justify-content-center my-4">
              <div class="col-2">
                <responsive-button  variant="success" pill text="Guardar" @ClickEvent="saveCheckListForm"
                                    responsive="md"  icon="archive-fill" size="sm"/>
              </div>
            </div>
          </b-container>
        </check-list>
        <div v-else>
          <b-alert show variant="warning" class="text-center text-capitalize">
            Este servico ha sido concluido sin haber relizado el check list
          </b-alert>
        </div>
      </template>
      <!-- CHECKLIST PRINCIPAL -->
    </template>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import api from '@/services/axios/index'
import checkList from '@/views/geocycle/service_plannings/components/checkList'
export default {
  name: 'check',
  components: {
    checkList,
  },
  props: {
    infoView: {
      default: false,
      type: [Boolean],
    },
  },
  data() {
    return {
      mainSpinner: true,
      spId: this.$route.params.spId,
      spCarrierId: this.$route.params.spCarrierId,
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
          name: 'service_plannings',
        },
        {
          breadcrumbName: 'Descarga de Materiales',
          aIcon: '',
          path: `/planeacion-servicios-descarga/${this.$route.params.spId}`,
        },
        {
          breadcrumbName: 'Check de Descarga',
          aIcon: '',
        },
      ],
      // Gondolas
      gondolaCheckListForm: {},
      showGondolaCheck: false,
      gondolaTotalFields: 0,
      // Gondolas
      showDischargeCheck: true,
      dischargeForm: {
        inspector_user_id: undefined,
        date: moment(),
        final_hour: moment(),
        comments: '',
      },
      dischargeRules: {
        inspector_user_id: [
          { required: true, message: 'El Nombre del inspector obligatorio.', trigger: 'blur' },
        ],
        date: [
          { required: true, message: 'La Fecha de Inspección es obligatoria.', trigger: 'blur' },
        ],
        final_hour: [
          { required: true, message: 'El Hora de Inspección es obligatoria.', trigger: 'blur' },
        ],
      },
      dischargeCheckListForm: {},
      inspectorsCatalog: [],
      totalFields: 0,
      checkListFormId: '0',
      gondolaCheckListFormId: '0',
    }
  },
  mixins: [getGeneralMixin],
  computed: {
    ...mapGetters('receptionServicePlannings', ['receptionState', 'getServiceID', 'getCheckStructure']),
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
  },
  methods: {
    // CheckList
    ...mapActions('receptionServicePlannings', ['SET_SERVICEID']),
    onGondola(checked) {
      this.showGondolaCheck = !!checked
    },
    async verifyIfCheckListFormExist() {
      const params = {
        'filter[servicePlannings]': this.spId,
        'filter[checks]': this.receptionState.dischargeCheck,
        'filter[servicePlanningsCarriers]': this.spCarrierId,
      }
      let booleanResponse = false
      await this.$store.dispatch('jv/get', ['checkListForms', {
        params: params,
      }])
        .then(response => {
          if (response.jsonApi.json.data.length > 0) {
            const keys = Object.keys(response)
            const checkListForm = response[keys[0]]
            this.checkListFormId = checkListForm.jsonApi.id
            this.verifyIfCheckListFormGondolaExist()
          }
          booleanResponse = response.jsonApi.json.data.length > 0
          this.showDischargeCheck = response.jsonApi.json.data.length > 0
        })
      return booleanResponse
    },
    async verifyIfCheckListFormGondolaExist() {
      const params = {
        'filter[servicePlannings]': this.spId,
        'filter[checks]': this.receptionState.gondolaCheck,
        'filter[servicePlanningsCarriers]': this.spCarrierId,
      }
      await this.$store.dispatch('jv/get', ['checkListForms', {
        params: params,
      }])
        .then(response => {
          if (response.jsonApi.json.data.length > 0) {
            const keys = Object.keys(response)
            const checkListForm = response[keys[0]]
            this.gondolaCheckListFormId = checkListForm.jsonApi.id
          }
        })
    },
    // Funcion que guarda el checkList de descarga segura
    saveGondolaCheckListForm() {
      // Validar el # de campos contestados
      if (this.gondolaTotalFields !== Object.keys(this.gondolaCheckListForm).length) {
        Modal.error({
          title: '¡Ha ocurrido un error!',
          content: 'Debe contestar todos los campos del Check List',
          zIndex: 3000,
        })
        return
      }
      const request = {
        check_id: this.receptionState.gondolaCheck,
        service_planning_id: this.spId,
        service_plannings_carrier_id: this.spCarrierId,
        inspectors: this.dischargeForm.inspector_user_id,
        date: this.dischargeForm.date,
        final_hour: moment(this.dischargeForm.final_hour).format('HH:mm'),
        comments: this.dischargeForm.comments,
        answers: this.gondolaCheckListForm,
      }
      return api.post('/saveCheckListAnswers', request)
    },
    // Funcion que guarda el checkList de descarga sencillo
    saveDischargeCheckListForm() {
      const request = {
        check_id: this.receptionState.dischargeCheck,
        service_planning_id: this.spId,
        service_plannings_carrier_id: this.spCarrierId,
        inspectors: this.dischargeForm.inspector_user_id,
        date: this.dischargeForm.date,
        final_hour: moment(this.dischargeForm.final_hour).format('HH:mm'),
        comments: this.dischargeForm.comments,
        answers: this.dischargeCheckListForm,
      }
      return api.post('/saveCheckListAnswers', request)
    },
    // Funcion que guarda ambos checkList
    verifyAnsweredChecks() {
      if (this.showGondolaCheck) {
        // Si se activa el check de gondolas se verifica que se contesten ambos checks descarga + gondolas
        // Validar el # de campos contestados
        if (this.totalFields !== Object.keys(this.dischargeCheckListForm).length && this.gondolaTotalFields !== Object.keys(this.gondolaCheckListForm).length) {
          Modal.error({
            title: '¡Ha ocurrido un error!',
            content: 'Debe contestar todos los campos del Check List',
            zIndex: 3000,
          })
          return false
        }
        return true
      } else {
        // Validar el # de campos contestados de descarga (sencillo)
        if (this.totalFields !== Object.keys(this.dischargeCheckListForm).length) {
          Modal.error({
            title: '¡Ha ocurrido un error!',
            content: 'Debe contestar todos los campos del Check List',
            zIndex: 3000,
          })
          return false
        }
        return true
      }
    },
    saveCheckListForm() {
      this.$refs['surveillance-Form'].validate(valid => {
        if (valid && this.verifyAnsweredChecks()) {
          if (this.showGondolaCheck) {
            Promise.all([this.saveDischargeCheckListForm(), this.saveGondolaCheckListForm()])
              .then(response => {
                // const general = response[0]
                // const gondola = response[1]
                this.verifyIfCheckListFormExist()
                this.mainSpinner = false
                Modal.success({
                  title: '¡Actualizado!',
                  content: 'Se ha registrado el CheckList exitosamente',
                  zIndex: 3000,
                })
              })
              .catch(() => {
                this.mainSpinner = false
              })
          } else {
            Promise.all([this.saveDischargeCheckListForm()])
              .then(response => {
                // const general = response[0]
                this.verifyIfCheckListFormExist()
                this.mainSpinner = false
                Modal.success({
                  title: '¡Actualizado!',
                  content: 'Se ha registrado el CheckList exitosamente',
                  zIndex: 3000,
                })
              })
              .catch(() => {
                this.mainSpinner = false
              })
          }
        } else return false
      })
    },
    async getInspectors() {
      const params = {
        sort: 'name',
      }
      this.inspectorsCatalog = await this.getSelectData('users', params)
    },
    // CheckList
  },
  async mounted() {
    this.mainSpinner = true
    if (!await this.verifyIfCheckListFormExist()) {
      await this.GET_CHECK(this.receptionState.dischargeCheck)
      await this.GET_CHECK(this.receptionState.gondolaCheck)
      await this.getInspectors()
    }
    this.mainSpinner = false
  },
}
</script>

<style scoped>

</style>
